import Vue from "vue"
import axios from "axios"

import _H from './helper'

export default {


  async getData({commit}, payload){

    let config = {
      headers: {
        "Access-Control-Allow-Origin":"*"
      }
    }

    return new Promise((resolve, reject) => {
      axios.get(process.env.VUE_APP_API_HOST + '/pathurl', config)
         .then(resp => {
           console.log("getDashboardData resp : ", resp)
           commit('LOAD_DATA', resp.data)
           resolve({success:true, data:resp.data})
         }).catch(err => {
           console.log("getDashboardData error : ", err)
           commit('LOAD_DATA', {})
           reject(err)
         })
     })
  }
}
