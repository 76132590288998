export default {
  data() {
    return {
        
    }
  },
  methods: {
    notify(pType, pTitle, pMessage) {
        // Create the title
        const vNodesTitle = this.$createElement(
            'div',
            { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
            [
                this.$createElement('h4', { class: 'mr-2' }, pTitle),
            ]
          )
          // Create the body
          const vNodesMsg = this.$createElement(
            'p',
            { class: ['text-center', 'mb-0'] },
            [
                this.$createElement('strong', pMessage),
            ]
          )
      this.$bvToast.toast([vNodesMsg], {
        title: [vNodesTitle],
        variant: pType,
        solid: true,
        appendToast: true,
        
      })
    },
  }
}
