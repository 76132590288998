import HomepageI18n from './homepage.i18n.js';
import LocalSwitch from './Localswitch.vue'


import {
  mapGetters,
  mapActions,
} from 'vuex'


export default {
  name: 'basecomponent',

  components: {
    LocalSwitch
  },

  props: {
    itemdata:undefined,
  },

  computed: {
    ...mapGetters(['']),
  },

  data() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  },

  methods: {
    ...mapActions([]),
    handleResize() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
    },

    checkText(elt){
      console.log("checkText: ",elt)
    }
  },

  watch: {
  },

  mounted() {

  },

  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  i18n: HomepageI18n,

}
