import mutations from "./mutations"
import actions from "./actions"


const state = {
  loaddata:undefined,
};

const getters = {
  loaddata: state => state.loaddata,
};

export default{
  state,
  actions,
  getters,
  mutations
}
