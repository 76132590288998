export default {
  messages: {
    en: {
      labels: {
        text: "Empty base component",
        title: "Update your password",
        language: "Language switcher :"
      },
      presentation:[
        {
          id: 1,
          text: ""
        }
      ],
      steps: [
        {
          id: 1,
          text: "Connect to your Pierre Fabre computer​"
        },
        {
          id: 2,
          text: " Close all running applications ",
        },
        {
          id:3,
          text:"Check that the FortiClient 6.4 application is connected. If not, right-click on the 'Connection...' icon and check by clicking on 'Remote Access': ",
          img:"img_step_3_en.png"
        },
        {
          id:4,
          text:"Once these verifications have been made, press the Ctrl + Alt + Delete keys simultaneously, then click on 'Change password'.​​"
        },
        {
          id:5,
          text:"Enter the temporary password received by sms, in the 'old password' field​"
        },
        {
          id:6,
          text: "Enter a new password never used (12 characters including upper and lower case, special characters and numbers) and repeat the entry. If you are confronted with the following window mentioning “The referenced account is currently locked and it may not be possible to log in “  wait up to 5 minutes before starting again.​​",
          img: "img_step_8.png"
        },
        {
          id:7,
          text: "If needed, Repeat steps 7 and 8 to change your password."
        },
        {
          id:-1,
          text:[{
            id:1,
            val:" ​The window ​"
          },
          {
            id:2,
            val:"“The password has been changed”",
            bold:1
          },
          {
            id:3,
            val:"must appear"
          }],
          img:"img_step_9_en.png"
        },
        {
          id:-2,
          text:[
            {
              id:1,
              val:"Please note that",
              color: 'red'
            },
            {
              id:2,
              val: "you must not connect",
              bold: 1,
              color: 'red'
            },
            {
              id:3,
              val: "to",
              color: 'red'
            },
            {
              id:4,
              val: "all business applications",
              bold:1,
              color:'red'
            },
            {
              id:5,
              val: "under any circumstances.",
              color:'red'
            }
          ]
        }

      ],
      info:{
        text:"In case of problem, thank you to contact your local IT manager"
      }
    },
    fr: {
      labels: {
        text: "Empty base component",
        title: "Changement de mot de passe",
        language: "Changement de langue "
      },
      presentation:[
        {
          id: 1,
          text: ""
        }
      ],
      steps: [
        {
          id:1,
          img: "img_steps_123.png"
        },
        {
          id:2,
          text:"Ces vérifications effectuées, tapez simultanément sur les touches Ctrl + Alt + Supp, puis sur « Modifier un mot de passe »",
        },
        {
          id:3,
          text:[
            {
              id: 1,
              val: "Saisissez​"
            },
            {
              id:2,
              val: "le mot de passe temporaire",
              bold: 1
            },
            {
              id:3,
              val:" reçu par sms, sur le champ « ancien mot de passe »"
            }
          ]
        },
        {
          id:4,
          text: "Saisissez un nouveau mot de passe jamais utilisé (12 caractères dont majuscules, minuscules, caractères spéciaux et chiffres) et renouvelez la saisie.​"
        },
        {
          id:-1,
          text: "Si vous êtes confronté (e) à la fenêtre suivante, patientez jusqu’à 5 minutes avant de recommencer à l’identique. ",
          img: "img_step_8.png"
        },
        {
          id:5,
          text:" Si besoin, renouvelez les étapes 5 et 6 pour modifier votre mot de passe",
          img:"img_step_7.png"
        },
        {
          id:-2,
          text:[
            {
              id:1,
              val:"Merci de noter que l’ensemble des",
              color: 'red'
            },
            {
              id:2,
              val: "applications métiers ",
              bold: 1,
              underline: 1,
              color: 'red'
            },
            {
              id:3,
              val: "(SAP, Ariba, LM7…) ne sont pas encore accessibles et que vous ne devez pas",
              color: 'red'
            },
            {
              id:4,
              val: "en aucun cas",
              bold:1,
              color: 'red'
            },
            {
              id:5,
              val: "vous y",
              color: 'red'
            },
            {
              id:6,
              val: "connecter.",
              bold: 1,
              underline:1,
              color: 'red'
            }
          ]
        }

      ],
      info: {
        text:"En cas de difficulté, merci de contacter le Support Informatique au 05.63.71.3636 (France et Belgique) et votre relais IT local en filiales."
      }
    },
  },
};
