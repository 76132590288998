import Vue from 'vue'
import VueRouter from 'vue-router'
import Home365 from '../components/homepage/homepage.vue'
import HomePass from '../components/homepage2/homepage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePass,
  },
  {
      path: '/o365',
      name: 'o356',
      component: Home365
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

export default router
