export default {
  messages: {
    en: {
      labels: {
        text: "Empty base component",
        title: "Update your password Office 365",
        language: "Language switcher :"
      },
      presentation:[
        {
          id:1,
          text: "This password must be modified from your Pierre Fabre iPad."
        },
        {
          id:2,
          text: "This password will only be valid for Office 365.​"
        },
        {
          id:3,
          warning: 'Be careful !  Your connection password to your session to START/UNLOCK YOUR EQUIPMENT must not be modified and will remain unchanged ',
          text: "as long as your equipment is not connected to the Pierre Fabre network (VPN, wired on site and WiFi Pierre Fabre)."
        },
        {
          id:4,
          text:"Please note that business applications (SAP, LM7...) are not yet available."
        },
        {
          id:5,
          underline:'If you have a business iPhone',
          text: ", you will be asked to go to the settings menu to change your Exchange password (Settings > Passwords & Accounts > Exchange ActiveSync > Account)"
        }
      ],
      steps: [
        {
          id: 1,
          text: "You will receive a sms with a temporary password"
        },
        {
          id: 2,
          text: "Connect to the url : ",
          link : "https://safi.pierre-fabre.com/adfs/portal/updatepassword"
        },
        {
          id:3,
          text: [
            {
              id:1,
              val:"Enter your "
            },
            {
              id:2,
              val:"login",
              bold:1
            },
            {
              id:3,
              val: " (Windows) followed by",
            },
            {
              id:4,
              val: "@pierre-fabre.com",
              bold:1
            },
            {
              id:5,
              val:"(see example bellow)"
            }
          ]
        },
        {
          id:4,
          text:"Enter the temporary password received by sms, in the 'old password' field)",
          img: "empty_pass.png"
        },
        {
          id:5,
          text: "Enter a new password (12 characters including uppercase, lowercase, special characters and numbers) and retype it. ",
          img: "fill_pass.png"
        },
        {
          id:6,
          text: [
            {
              id:1,
              val: "Click on ​​"
            },
            {
              id:2,
              val: "Send",
              underline: 1,
              bold: 1
            },
            {
              id:3,
              val: "then on "
            },
            {
              id:4,
              val: "Update",
              underline: 1,
              bold: 1
            }
          ],
          img: "new_pass.png"
        }
      ],
      info:{
        text:"In case of problem, thank you to contact your local IT manager"
      }
    },
    fr: {
      labels: {
        text: "Empty base component",
        title: "Changement de mot de passe Office 365",
        language: "Changement de langue "
      },
      presentation:[
        {
          id: 1,
          text: "Ce mot de passe, doit être modifié depuis votre iPad Pierre Fabre."
        },
        {
          id: 2,
          text: "Ce mot de passe ne sera valable que pour les applications Office 365.​"
        },
        {
          id: 3,
          warning: 'Attention ! Votre mot de passe de connexion à votre session pour DEMARRER/DEVERROUILLER VOTRE MATERIEL ne doit pas être modifié',
          text: "et restera inchangé tant que votre matériel ne sera pas connecté au réseau Pierre Fabre (VPN, filaire sur site et WiFi Pierre Fabre).​"
        },
        {
          id: 4,
          text:"Merci de noter que les applications métiers (SAP, LM7…) ne sont pas encore accessibles"
        },
        {
          id: 5,
          underline:'Si vous êtes détenteur d’un smartphone professionnel',
          text: ", il vous sera demandé d’aller dans les paramètres pour changer votre mot de passe Exchange (Réglages > Mots de passe et comptes > Exchange ActiveSync > Compte)​"
        }
      ],
      steps: [
        {
          id: 1,
          text: "Vous recevez un sms contenant le mot de passe temporaire"
        },
        {
          id: 2,
          text: "Connectez-vous à l'url : ",
          link : "https://safi.pierre-fabre.com/adfs/portal/updatepassword"
        },
        {
          id:3,
          text: [
            {
              id:1,
              val: "Saisissez votre "
            },
            {
              id:2,
              val: " identifiant ",
              bold: "1"
            },
            {
              id:3,
              val: " (login Windows) suivi de "
            },
            {
              id:4,
              val: "@pierre-fabre.com",
              bold: "1"
            },
            {
              id:5,
              val: " (cf ex ci-dessous)"
            }
          ]
        },
        {
          id:4,
          text:"Saisissez le mot de passe temporaire dans la case 'Ancien mot de passe'",
          img: "empty_pass.png"
        },
        {
          id:5,
          text: "Saisissez un nouveau mot de passe (12 caractères minimum : minuscule(s), majuscule(s), chiffre(s) et caractère(s) spéciale(s)), renouvelez la saisie.",
          img: "fill_pass.png"
        },
        {
          id:6,
          text: [
            {
              id:1,
              val: "Cliquez sur ​"
            },
            {
              id:2,
              val: "Envoyer",
              underline: 1,
              bold: 1
            },
            {
              id:3,
              val: " puis sur "
            },
            {
              id:4,
              val: "Mettre à jour",
              underline: 1,
              bold: 1
            }
          ],
          img: "new_pass.png"
        }
      ],
      info: {
        text:"En cas de difficulté, merci de contacter le Support Informatique au 05.63.71.3636 (France et Belgique) et votre relais IT local en filiales."
      }
    },
  },
};
