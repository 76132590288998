<template>
  <div class="locale-changer">
    <i :class="`${$i18n.locale} `"></i>
    <select v-model="$i18n.locale" class="selector-display btn-style">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
        {{lang == 'fr' ? 'Français' : 'English'}}
      </option>
    </select>
  </div>
</template>

<style>
.flag::before {
    display: inline-block;
    content: '';
    background: url("https://mdbootstrap.com/img/svg/flags.png") no-repeat -108px -1976px;
    width: 16px;
    height: 11px;
    position: relative;
    left: 17px;
    z-index: 0;
  }

  i.flag.fr::before, i.flag.france::before {
      background-position: 0px -1976px;
  }

  i.flag.en:before, i.flag.england::before, i.flag.en-US:before {
      background-position: -36px -26px;
  }

  .btn-style{
    padding: 5px;
    border: solid 1px gray;
    border-radius: 5px;
    margin-left: 10px;
  }

  .selector-display {
    background-color: transparent;
    /* border: none; */
    color: black;
    cursor: pointer;
    position: relative;
    z-index: 10;
  }

  select {
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
  }
</style>

<script>
export default {
  name: 'locale-changer',
  data () {
    return { langs: ['fr', 'en'] }
  }
}
</script>
