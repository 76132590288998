import Vue from 'vue'
import App from './App.vue'
import Notifications from './mixins/notifications.js'

import i18n from "./i18n"
import store from './store'
import router from './router'

Vue.config.productionTip = false

new Vue({
  mixins: [Notifications],
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
